<script lang="ts" setup>
enum Size {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

const textareaRef = ref<HTMLElement | null>(null)

const adjustTextarea = () => {
  if (textareaRef.value) {
    textareaRef.value.style.height = 'auto'
    textareaRef.value.style.height = textareaRef.value.scrollHeight + 2 + 'px'
  }
}

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  rows: {
    type: Number,
    default: 1,
  },
  size: {
    type: String,
    default: Size.Medium,
    validator: (value: string) => Object.values(Size).includes(value as Size),
  },
})

const padding = computed(() => {
  switch (props.size) {
    case Size.Large:
      return 'p-4 rounded-2xl'

    case Size.Medium:
      return 'p-3 rounded-xl'

    case Size.Small:
      return 'py-2.5 px-3 rounded-[0.625rem]'

    case Size.XSmall:
      return 'py-1.5 px-2.5 rounded-lg'

    default:
      return ''
  }
})

watch(
  () => props.modelValue,
  () => {
    nextTick(() => {
      adjustTextarea()
    })
  },
)

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void
}>()

const onInput = (event: Event) => {
  const inputElement = event.target as HTMLInputElement
  adjustTextarea()
  emit('update:modelValue', inputElement.value)
}
</script>

<template>
  <textarea
    ref="textareaRef"
    :value="modelValue"
    :rows="rows"
    style="resize: none; overflow: hidden"
    :class="padding"
    class="border-border-strong border"
    :placeholder="placeholder"
    @input="onInput($event)"
  ></textarea>
</template>
